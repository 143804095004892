import 'core-js/web/immediate'

import jwt from 'jsonwebtoken'

export async function verifyJWT(token, opts = {}) {
  return new Promise((resolve, reject) => {
    jwt.verify(token, opts, (err, decoded) => {
      if (err) reject(err)
      else resolve(decoded)
    })
  })
}

export default verifyJWT

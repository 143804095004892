import { useContext, useCallback } from 'react'

import { useComponentDidMount } from '@/utils/hooks'
import CompanyContext from '@/utils/context/CompanyContext'
import getCompanyFromUrl from '@/utils/getCompanyFromUrl'
import { getPrefixes } from '@/utils'
import { getTheme } from '@/utils/styling'

export const useCompany = (newValue) => {
  const {
    company,
    setCompany: _setCompany,
    setTheme,
    setPrefixes,
  } = useContext(CompanyContext)

  const setCompany = useCallback(
    (value) => {
      const company = String(value).toLowerCase()
      _setCompany(company)
      setPrefixes(getPrefixes(company))
      const theme = getTheme(company)
      setTheme(theme)
    },
    [_setCompany, setTheme, setPrefixes]
  )

  useComponentDidMount(() => {
    if (typeof window !== 'undefined') {
      if (typeof newValue === 'string' && company !== newValue) {
        setCompany(newValue || company)
      } else {
        const companyFromUrl = getCompanyFromUrl({ url: window.location.href })
        if (company !== String(companyFromUrl).toLowerCase())
          setCompany(companyFromUrl)
      }
    }
  })

  return {
    company,
    setCompany,
  }
}

export default useCompany

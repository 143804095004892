export default function getPrefixes(company = 'core') {
  const comps = [
    'login',
    'badge',
    'dashboard__image',
    'text',
    'custom-control-input',
  ]
  return comps.reduce((accum, comp) => {
    accum[comp] = `${comp}-${company}`
    return accum
  }, {})
}

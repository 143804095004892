import { createContext, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import ReactBootstrapThemeProvider from 'react-bootstrap/ThemeProvider'
import PropTypes from 'prop-types'

import { getTheme, GlobalStyle } from '@/utils/styling'
import { getPrefixes } from '@/utils'

export const CompanyContext = createContext({
  company: 'pointcore',
  setCompany: () => {},
  setTheme: () => {},
  setPrefixes: () => {},
})

export const CompanyProvider = ({ children }) => {
  const [company, setCompany] = useState()
  const [theme, setTheme] = useState(getTheme(company))
  const [prefixes, setPrefixes] = useState(getPrefixes(company))

  return (
    <CompanyContext.Provider
      value={{ company, setCompany, setTheme, setPrefixes }}
    >
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <ReactBootstrapThemeProvider prefixes={prefixes}>
          {children}
        </ReactBootstrapThemeProvider>
      </ThemeProvider>
    </CompanyContext.Provider>
  )
}

CompanyProvider.propTypes = {
  children: PropTypes.node,
}

export default CompanyContext

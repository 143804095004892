const setWithExpiry = (key, value, ttl = 60 * 60 * 24) => {
  if (typeof window !== 'undefined') {
    const now = new Date()
    const item = {
      value,
      expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item))
  }
}

export default setWithExpiry

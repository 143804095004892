import cookies from 'next-cookies'
import { verifyJWT } from './jwt'
import { COOKIE_KEYS } from '../constants'

export const verifyCookie = async (ctx) => {
  const token = cookies(ctx)[COOKIE_KEYS.TOKEN]
  const decoded = await verifyJWT(token).catch(() => null)
  if (!decoded) return null

  const { exp } = decoded

  return exp > Math.ceil(Date.now() / 1000) ? decoded : null
}

import { useEffect } from 'react'

export const useDeepCompareEffect = (callback, deps) => {
  useEffect(
    callback,
    deps.map((i) => JSON.stringify(i))
  )
}

export default useDeepCompareEffect

// Old function, using Intl API - not compatible with Safari, but hard to check for browser agent from server-side
// where you can't access the window.navigator APIs
// export const formatDollars = (amount = 0, short = true) => {
//   const options = {
//     style: 'currency',
//     currency: 'USD',
//     minimumFractionDigits: 0,
//     maximumFractionDigits: 0,
//     ...(short && {
//       notation: 'compact',
//       compactDisplay: 'short',
//     }),
//   }

//   return new Intl.NumberFormat('en-US', options).format(amount)
// }

// adapted from https://stackoverflow.com/a/9462382
export const longFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
})

/**
 * @description Manual formatting utility for currency
 * @param {number} num The number to format
 * @param {number} digits The number of significant digits
 * @return {String} formatted number
 */
export const formatDollars = (num, digits = 0) => {
  if (Number.isNaN(Number(num))) return '$0'

  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  let i
  for (i = si.length - 1; i > 0; i--) {
    if (Math.abs(num) >= si[i].value) {
      break
    }
  }
  if (num < 0) {
    const newN = Math.abs(num)
    return (
      `-$` +
      (newN / si[i].value).toFixed(digits).replace(rx, '$1') +
      si[i].symbol
    )
  } else {
    return (
      `$` + (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol
    )
  }
}

export default formatDollars

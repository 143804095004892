import { useEffect, useState } from 'react'

export const useStickyScroll = ({ parentRef, handleScroll }) => {
  const [sticky, setSticky] = useState({ isSticky: false, offset: 0 })

  useEffect(() => {
    const parent = parentRef?.current?.getBoundingClientRect()
    const handleScrollEvent = () => {
      setSticky(
        handleScroll({ topOffset: parent?.top, height: parent?.height })
      )
    }

    window.addEventListener('scroll', handleScrollEvent)
    return () => {
      window.removeEventListener('scroll', handleScrollEvent)
    }
  }, [parentRef])

  return sticky
}

export default useStickyScroll

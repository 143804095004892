import { COMPANIES } from './constants'

function getCompanyFromUrl({ url = '', defaultCompany = 'pointcore' }) {
  const company = COMPANIES.find((company) => {
    const regexp = url.includes('/login')
      ? new RegExp(company, 'i')
      : new RegExp('\\b' + company + '\\b', 'gi')
    return regexp.test(url)
  })

  if (!company) return defaultCompany
  return company.toLowerCase()
}

export default getCompanyFromUrl

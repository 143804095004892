function EditColumnCellRenderer() {}
EditColumnCellRenderer.prototype.init = function (params) {
  // check if the field has a value
  const cellBlank = !params.value
  if (cellBlank) {
    return null
  }
}

EditColumnCellRenderer.prototype.getGui = function () {
  return this.ui
}

export default EditColumnCellRenderer

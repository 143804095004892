import { useEffect, useCallback, useState } from 'react'
import { useApolloClient } from '@apollo/client'

import useCompany from '@/utils/hooks/useCompany'
import { useIdentity } from '@/utils/passport/withIdentity'

export const useAuthUser = (callback) => {
  const { company: _company } = useCompany()
  const apolloClient = useApolloClient()
  const [primed, setPrimed] = useState(false)
  const loggedInUser = useIdentity()
  const [user, setUser] = useState()

  useEffect(() => {
    if (typeof callback === 'function') {
      callback({ getUserByEmail: loggedInUser })
    }

    if (loggedInUser) {
      setUser(loggedInUser)
      setPrimed(true)
    }
  }, [callback, loggedInUser])

  const logout = useCallback(async () => {
    if (typeof window !== 'undefined') {
      // clear apollo client cache
      apolloClient?.clearStore()

      // clear local storage
      localStorage?.removeItem('grid-context')
      localStorage?.removeItem('user')

      window.location = '/api/auth/logout'
    }
  }, [apolloClient])

  const permissionCheck = useCallback(
    ({ company = _company, module, feature, region, program } = {}) => {
      if (user?.superAdmin) {
        return {
          module: 'ADMIN',
          feature: 'ADMIN',
          region: 'ALL',
          program: 'ALL',
          regions: [],
          projects: user?.projects || [],
        }
      }

      const permissionTemplate =
        company &&
        user?.permissionTemplates?.find(
          (template) =>
            template?.company?.id?.toUpperCase() === company.toUpperCase()
        )

      if (!permissionTemplate)
        return {
          module: 'NONE',
          feature: 'NONE',
          region: 'NONE',
          program: 'NONE',
          regions: [],
          projects: [],
        }

      const permissionModule =
        module &&
        permissionTemplate?.permissions?.modules?.find(
          ({ name }) => name.toUpperCase() === module.toUpperCase()
        )

      let permissionFeature =
        feature &&
        module?.features?.find(
          ({ name }) => name.toUpperCase() === feature.toUpperCase()
        )
      if (!permissionFeature) {
        permissionFeature = permissionModule
      }

      const permissionRegion =
        region &&
        user?.permissionTemplates?.find(
          (template) =>
            template?.region?.name?.toUpperCase() === region.toUpperCase()
        )
      const permissionProgram =
        program &&
        permissionRegion?.programs?.find(
          ({ name }) => name?.toUpperCase() === program?.toUpperCase()
        )

      const regions = permissionTemplate?.permissions?.regions

      return {
        module: permissionModule?.permission || 'UNKNOWN',
        feature: permissionFeature?.permission || 'UNKNOWN',
        region: permissionRegion?.permission || 'UNKNOWN',
        program: permissionProgram?.permission || 'UNKNOWN',
        regions: regions || [],
        projects: user?.projects || [],
      }
    },
    [user, _company]
  )

  return {
    user,
    primed,
    permissionCheck,
    logout,
  }
}

export default useAuthUser

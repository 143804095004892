export type User = {
  id: string
  email: string
  name: string
}

export interface UsePermissionProp {
  project?: {
    id?: string
    small?: boolean
    region?: {
      name?: string
    }
  }
  module: any
  feature: any
}

export enum ModulePolicyEnum {
  NONE = 'NONE',
  MODULE_SPECIFIC = 'MODULE_SPECIFIC',
  ALL_STANDARD = 'ALL_STANDARD',
  ADMIN = 'ADMIN',
}

export enum FeaturePolicyEnum {
  NONE = 'NONE',
  READ = 'READ',
  STANDARD = 'STANDARD',
  ADMIN = 'ADMIN',
}

export enum RegionPolicyEnum {
  NONE = 'NONE',
  REGION_SPECIFIC = 'REGION_SPECIFIC',
  ALL = 'ALL',
}

export enum RegionSpecificStandardPolicyEnum {
  NONE = 'NONE',
  ASSIGNED = 'ASSIGNED',
  SMALL_AND_ASSIGNED = 'SMALL_AND_ASSIGNED',
  STANDARD_AND_ASSIGNED = 'STANDARD_AND_ASSIGNED',
  ALL = 'ALL',
}

export enum RegionSpecificProgramPolicyEnum {
  NONE = 'NONE',
  PROGRAM_SPECIFIC = 'PROGRAM_SPECIFIC',
  ALL = 'ALL',
}

export enum ProgramPolicyEnum {
  NONE = 'NONE',
  ASSIGNED = 'ASSIGNED',
  ALL = 'ALL',
}

export type Template = {
  name?: string
  company?: string | number
  modules?: ModulePolicySet
  regions?: RegionPolicySet
}

// moduleName really should be: string | FeaturePolicySet | ModulePolicyEnum
export type ModulePolicySet = {
  policy: ModulePolicyEnum
  [moduleName: string]: any
}

export type FeaturePolicySet = {
  policy: FeaturePolicyEnum
  [featureName: string]: FeaturePolicyEnum
}

export type RegionPolicySet = {
  policy: RegionPolicyEnum
  [regionName: string]: any // RegionPolicy | RegionPolicyEnum;
}

export type RegionPolicy = {
  policy: RegionSpecificStandardPolicyEnum | RegionSpecificProgramPolicyEnum
  [programName: string]:
    | ProgramPolicy
    | RegionSpecificStandardPolicyEnum
    | RegionSpecificProgramPolicyEnum
}

export type ProgramPolicy = {
  policy: ProgramPolicyEnum
}

export type SetFeaturePolicyParams = {
  module: string
  feature: string | ModulePolicyEnum
  policy: FeaturePolicyEnum
}

export type AddFeatureParams = {
  module: string
  feature: string
  content: FeaturePolicySet
}

export type RemoveFeatureParams = {
  module: string
  feature: string
}

export interface PermissionsMiddleware {
  template?: Template
  initTemplate(template?: Template): void
  canAccessRegion(name: string): boolean
  canAccessModule(name: string): boolean
  canAccessFeature(name: string): boolean
}

export interface PermissionsInterface {
  template: Template
  initTemplate(template: string): void
  clearTemplate(): void
  setModulePolicy(policy: ModulePolicyEnum): void
  addModule(name: string, content?: FeaturePolicySet | ModulePolicyEnum): void
  removeModule(name: string): void
  setFeaturePolicy(params: SetFeaturePolicyParams): void

  setRegionPolicy(policy: RegionPolicyEnum): void
  addRegion(
    name: string,
    policy: RegionSpecificStandardPolicyEnum | RegionSpecificProgramPolicyEnum
  ): void
  removeRegion(name: string): void
  addProgramToRegion(
    region: string,
    program: string,
    policy: RegionSpecificStandardPolicyEnum | RegionSpecificProgramPolicyEnum
  ): void
  removeProgramFromRegion(region: string, program: string): void
}
